/* @license
 *
 * © 2021 Daniel Aleksandersen <https://www.daniel.priv.no/>
 * SPDX-License-Identifier: Apache-2.0
 *
 * © 2016–2017 The New York Times Company <https://www.nytco.com/>
 * SPDX-License-Identifier: Apache-2.0
 */

export class textBalancer {
    // initializes recursive binary search
    initialized = false
    resizeObserver = null
    alwaysRun = false
    el = null

    opts = {

    }



    mutateConfig = { childList: true, subtree: true };

    observerFunction = ()=>{}

    constructor(el, opts = {  }){

        this.el = el
    }


    balanceText = () => {

        if (this.textElementIsMultipleLines(this.el)) {
            this.el.style.maxWidth = null;
            var width = this.el.parentElement.clientWidth;
            var bottomRange = Math.max(100, parseInt(width / 2));



            this.squeezeContainer(
                this.el,
                this.el.clientHeight,
                bottomRange,
                width
            );
        }
        // reveal text; paired with the .balance-text class that hides text
        this.el.classList.replace("balance-text", "balanced-text");
    };

    // Make the headline element as narrow as possible while maintaining its current height (number of lines). Binary search.
    squeezeContainer = (
        headline,
        originalHeight,
        bottomRange,
        topRange
    ) => {
        var mid;
        if (bottomRange + 4 >= topRange) {
            headline.style.maxWidth = Math.ceil(topRange) + "px";
            return;
        }
        mid = (bottomRange + topRange) / 2;
        headline.style.maxWidth = mid + "px";

        if (headline.clientHeight > originalHeight) {
            // we've squoze too far and headline has spilled onto an additional line; recurse on wider range
            this.squeezeContainer(headline, originalHeight, mid, topRange);
        } else {
            // headline has not wrapped to another line; keep squeezing!
            this.squeezeContainer(headline, originalHeight, bottomRange, mid);
        }
    };

    // check if element text spans multiple lines
    textElementIsMultipleLines = () => {
        var elementStyles = window.getComputedStyle(this.el);
        var elementLineHeight = parseInt(elementStyles["line-height"], 10);
        var elementHeight = parseInt(elementStyles["height"], 10);

        return elementLineHeight < elementHeight;
    };

    initialize = () => {
        // future-proofing: the browser natively supports text balancing
        //if (window.CSS && CSS.supports('text-wrap', 'balance'))
        //{
        //  return;
        //}

        this.observerFunction = (entries) => {
            this.balanceText()
           /* entries.forEach((entry) => {

                console.log('REBALANCING')

                let elements = entry.target.querySelectorAll(
                    ".balance-text, .balanced-text"
                );
                for (let element of elements) {

                    this.balanceText(element);
                }
            });*/
        };
        this.resizeObserver = new ResizeObserver(this.observerFunction);
        this.mutateObserver = new MutationObserver(this.observerFunction);



        if (window.ResizeObserver && window.MutationObserver) {
            //for (let element of candidates) {
            this.resizeObserver.observe(this.el.parentElement);
            this.mutateObserver.observe(this.el, this.mutateConfig);
            //}
        } else {
            this.balanceText();
        }
    };

    // run now or when document has loaded
    //if (['complete', 'interactive'].includes(document.readyState))
    //{

    //}
    //else
    //{
    //  document.addEventListener('readystatechange', initialize);
    //}
}

// timer-based fallback if text doesn’t appear after three seconds
/*
(function ()
{
  function revealText()
  {
    var texts = document.querySelectorAll('.balance-text');
    if (texts)
    {
      for (var index = 0; index < texts.length; index++)
      {
        var text = texts[index];
        if (text.classList)
        {
          // classList.replace isn’t supported in older browsers
          text.classList.remove('balance-text');
          text.classList.add('balanced-text');
        }
      }
    }
  }

  setTimeout(revealText, 3000);
})();*/
