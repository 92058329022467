<template>
    <div class="wonderguide-masthead w-1/2 p-8 flex-shrink min-w-0 h-screen relative">

        <div class="wonderguide-mast-background absolute w-full h-full z-[-1] left-0 top-0">
            <transition-group name="masthead-fade" v-if="loadedImages.length">
                <div class="masthead-image overflow-hidden h-full w-full absolute top-0 left-0" v-for="(image, index) in loadedImages" :key="index" v-show="activeImage == image" >
                        <img :src="image"  class="h-full w-full object-cover">
                </div>
            </transition-group>
        </div>

        <div class="masthead-content text-white drop-shadow-text flex flex-col h-full justify-between">
            <div class="masthead-topper">
                <div class="menu-wrapper flex justify-between items-center px-1 pt-2 pb-5 border-white-dotted leading-7">
                    <h3 class="text-logo font-semibold uppercase" v-html="account.name"></h3>
                    <div class="menu-items flex flex-wrap justify-end">
                        <template v-for="(item, key) in menuItems" :key="key">
                            <Link class="text-menu-item leading-[22px] tracking-wide font-semibold uppercase menu-item hover:text-amber-200" :href="props.urlPrefix+item.url" v-html="item.label" />
                            <span class="menu-bullet" v-if="key < menuItems.length - 1"></span>
                        </template>
                    </div>
                </div>
                <div class="info px-2 py-3">
                    <span class="text-[.8rem] uppercase font-semibold tracking-wider" v-html="`${guide.name} EDITION`"></span>
                </div>
            </div>

            <div class="masthead-title p-3 max-w-[34rem] drop-shadow-text">
                <h3 class="uppercase font-sans text-[.8rem] font-semibold tracking-widest pl-[4px]" v-html="subtitle" v-if="subtitle"></h3>
                <h2 class="text-[4rem] font-bold leading-none" v-html="props.title"></h2>
            </div>
        </div>
    </div>
</template>
<script setup>

    const props = defineProps({
        title: {
            type: String
        },
        subtitle: {
            type: String
        },
        currentSection: {
            type: String
        },
        images: {
            type: Array
        },
        urlPrefix: {
            default: ''
        }
    })

    const baseItems = {
        'stories': {
            url: '/stories',
            label: 'Stories'
        },
        'todo': {
            url: '/things-to-do',
            label: 'Things to Do'
        },
        'tours': {
            url: '/tours',
            label: 'Tours'
        },
        'spotlight': {
            url: '/spotlight',
            label: 'Spotlight'
        },
        'visit': {
            url: '/visit',
            label: 'Visit'
        }
    }
    const menuItems = computed(() => {
        //let finalItems = []
        return Object.entries(baseItems).filter(([key, item]) => {
            //if ( guide.value.counts[key] ) {
            //    finalItems.push( item )
            //}
            return guide.value.counts[key]
        })
        //return finalItems
    })

</script>
