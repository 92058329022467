<template>

    <div class="mobile-menu-wrapper z-10 bg-[#1B1D1D] backdrop-blur-[3px] bg-opacity-80 top-0 left-0 flex fixed md:absolute items-center justify-center w-full h-full">

        <slot />

        <div class="menu-items flex flex-col items-center flex-shrink-0">
            <template v-for="(item, key) in menuItems" :key="key">

                <Link class="text-[1rem] menu-item group p-2.5 leading-[26px] text-center whitespace-nowrap transition-all duration-300 font-semibold uppercase w-[200px] menu-item"

                    :style="{ 'transition-delay': (key*.1)+'s' }"
                    :href="urlPrefix+item.url"
                    >
                    <span :class="{'text-yellow-400 group-hover:text-yellow-300': $page.url.startsWith(urlPrefix+item.url), 'text-white group-hover:opacity-70': !$page.url.startsWith(urlPrefix+item.url) }" class="transition-all duration-300 ease-out group-hover:tracking-widest tracking-wider " v-html="item.label"></span>
                </Link>

            </template>
        </div>

    </div>

</template>
<script setup>
    import { usePage } from "@inertiajs/inertia-vue3"
    import { computed, ref } from "vue"



    const props = defineProps({
        mobileOpen: {
            type: Boolean,
            default: false
        }
    })

    const urlPrefix = computed(() => usePage().props.value.urlPrefix)

    const guide = computed(() => {
        return usePage().props.value.guide
    })

    const baseItems = {
        'stories': {
            url: '/stories',
            label: 'Stories'
        },
        'todos': {
            url: '/things-to-do',
            label: 'Things to Do'
        },
        'events': {
            url: '/events',
            label: 'Calendar'
        },
        'tours': {
            url: '/tours',
            label: 'Tours'
        },
        'spotlight': {
            url: '/spotlight',
            label: 'Spotlight'
        },
        'visit': {
            url: '/visit',
            label: 'Visit'
        }
    }
    const menuItems = computed(() => {
        let finalItems = []
        Object.entries(baseItems).forEach(([key, item]) => {
            if ( key == 'events' ) {
                if ( usePage().props.value.account.filters.api_keys.calendar ) {
                    finalItems.push( item )
                }
            } else if ( guide.value.counts[key] ) {
                finalItems.push( item )
            }
        })
        return finalItems
    })

</script>
