<template>
    <div class="contact-footer bg-gray-50 relative flex flex-col items-center justify-center form">

        <div class="newsletter-block relative px-4 py-12">



            <h3 class="text-xl text-gray-600 text-center">Join our Newsletter</h3>

            <transition name="fade">
                <div v-if="submitting" class="spinner-wrapper top-0 left-0 z-[2] absolute flex items-center justify-center w-full h-full bg-gray-50 bg-opacity-90">
                    <!--<div class="spinner text-3xl text-gray-400">
                        <span class="iconify" data-icon="ph:circle-notch-bold"></span>
                    </div>-->
                    <svg class="animate-spin -ml-1 mr-3 h-7 w-7 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
            </transition>

            <transition name="fade">
                <div v-if="success" class="success-message absolute top-0 left-0 flex z-[2] items-center justify-center w-full h-full bg-gray-50 bg-opacity-90">

                    <span class="font-semibold text-gray-600">Thank you! We'll be in touch soon.</span>

                </div>
            </transition>

            <form :class="{ 'pointer-events-none': submitting || success }" class="mt-5" @submit.prevent="submitForm">
                <div class="flex flex-col md:flex-row items-center justify-center w-full">

                    <div class="checkbox px-1.5 md:pl-6 md:pr-0 max-w-[400px] flex justify-center leading-[12px]">
                        <span class="h-[14px] leading-[14px] mr-1.5">
                            <input :class="{'text-red-600 ring-2 ring-red-200 border-red-600': validator.confirm.$error, 'text-cyan-500': !validator.confirm.$error }" v-model="form.confirm" class="form-checkbox checked:border-0 checked:bg-cyan-600 ring-cyan-500 focus:ring-cyan-500 checked:border-gray-500 border-gray-400 w-[14px] h-[14px] leading-[14px] align-middle rounded" type="checkbox">
                        </span>
                        <span class="checkbox-text text-sm text-gray-500 leading-[16px]">
                            I agree to receive communications from Wonder Guides, EverWondr and its tourism partners
                        </span>
                    </div>

                    <div class="form-inputs flex items-center  w-full max-w-[280px]">
                        <div class="email-input relative flex-grow mr-1.5">
                            <input v-model="form.email" name="email" class="form-input w-full bg-white focus:bg-opacity-90 default:border-gray-300 text-gray-500 focus:border-gray-300 focus:outline-none focus:ring-0 border-opacity-50 focus:border-opacity-70 rounded" placeholder="Enter your email" type="text" :class="{ 'border-red-600': validator.email.$error, 'border-gray-300 focus:border-gray-400': !validator.email.$error, 'bg-opacity-90': form.email, 'bg-opacity-60': !form.email }" />
                            <span v-if="validator.email.$error" class="text-[.78rem] px-2 py-[1px] text-white shadow font-bold rounded-b-sm w-auto absolute top-full mt-[-1px] right-[3px] bg-red-600 block text-right">
                                A valid email is required.
                            </span>
                        </div>

                        <button type="submit" class="form-button rounded h-[2.5rem] w-[2.5rem] hover:bg-opacity-90 text-white bg-gray-400 flex items-center justify-center">
                            <span class="iconify" data-icon="ph:paper-plane-tilt-bold"></span>
                        </button>
                    </div>


                </div>
                <div class="captcha-info text-center mt-4 text-xs text-gray-400 text-opacity-70">
                    This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                </div>

            </form>

        </div>

        <Link href="/sales" class="about-cta group text-gray-300 w-full px-6 py-10 bg-gray-200 bg-opacity-60 block">
            <div class="flex justify-center items-center">
                <SiteIcon class="text-gray-400 transition-colors duration-500 group-hover:text-cyan-600 w-[52px] h-[52px] mr-2.5"></SiteIcon>
                <div class="cta-body tracking-wide leading-snug">
                    <h4 class="font-sans text-gray-400 font-semibold text-[1.15rem]">Work with us to create content for your destination</h4>
                </div>
            </div>
        </Link>

    </div>
</template>
<script setup>

    import { ref, inject } from 'vue'

    import useVuelidate from '@vuelidate/core'
    import { required, email, helpers, sameAs } from '@vuelidate/validators'

    import { useReCaptcha } from 'vue-recaptcha-v3'

    import SiteIcon from '@/images/icon.svg';

    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

    const props = defineProps({
        account: {},
        guide: {}
    })

    const axios = inject('axios')

    const form = ref({
        email: '',
        confirm: false,
        account: props.account.uuid,
        guide: props.guide.uuid
    })

    const submitting = ref(false)
    const success = ref(false)


    const rules = {
      confirm: { required, sameAs: sameAs(true) },
      email: {
        required,
        email
      }
    }
    const validator = useVuelidate(rules, form)

    const captchaToken = ref(null)

    const recaptcha = async () => {
      // (optional) Wait until recaptcha has been loaded.
      await recaptchaLoaded()



      // Execute reCAPTCHA with action "login".
      return await executeRecaptcha('signup')



      // Do stuff with the received token.
    }

    const submitForm = async () => {


        const pendingToken = await recaptcha()

        const formValid = await validator.value.$validate()

        if ( formValid && pendingToken ) {
            submitting.value = true

            axios.post('/signup/process', {
                ...form.value,
                token: pendingToken
            })
            .then(function (response) {
                console.log(response);

                submitting.value = false
                success.value = true
            })

            /*setTimeout(
                () => {
                    success.value = true
                    submitting.value = false
                },
                2000
            )*/
        }

    }


</script>
