<template>
    <div ref="mastRef" class="wonderguide-mast-background bg-gray-900 absolute w-full h-full z-[-1] left-0 top-0">
        <transition-group name="masthead-fade" v-if="loadedImages.length" appear>
            <div class="masthead-image overflow-hidden h-full w-full z-[2] absolute top-0 left-0" v-for="(image, index) in loadedImages" :key="index" v-show="activeImage == image.original">
                <img crossorigin="Anonymous" :src="image.updated" class="h-full w-full object-cover">
            </div>
        </transition-group>


        <teleport to="body">
            <div style="display:none;">
                <img class="img-hidden" />
                <canvas class="canvas-hidden"></canvas>
            </div>

            <transition name="overlay-fade" appear>
                <div v-if="!loadedImages.length" class="loading-overlay fixed top-0 left-0 w-screen h-screen z-30 bg-gray-100 flex items-center justify-center">
                    <div class="loading-wrap">
                    <svg class="animate-spin -ml-1 h-7 w-7 text-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    </div>
                </div>
            </transition>
        </teleport>
    </div>
</template>
<script setup>

    import { watch, inject, ref, computed } from 'vue'
    import { useDebounceFn, useResizeObserver } from '@vueuse/core'

    import smartcrop from 'smartcrop'

    const mastRef = ref()
    const imageRef = ref()
    const sharedData = inject('sharedData')
    const activeImage = ref(null)
    const loadedImages = ref([])

    const imgEl = ref(null)

    useResizeObserver(document.body, useDebounceFn((entries) => {
        if ( imgEl.value ) {
            reflow()
        }
    }, 500))

    const reflow = useDebounceFn(async () => {

        if ( document.fonts ) {
            let fontsReady = await document.fonts.ready;
        }

        let width = mastRef.value.clientWidth
        let height = mastRef.value.clientHeight

        smartcrop.crop(imgEl.value, { width: width, height: height }).then(function(result) {


            var crop = result.topCrop;
            var canvas = document.querySelector('.canvas-hidden');
            var ctx = canvas.getContext('2d');
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(
                imgEl.value,
                crop.x,
                crop.y,
                crop.width,
                crop.height,
                0,
                0,
                canvas.width,
                canvas.height
            );

            let imgSrc = canvas.toDataURL("image/jpg", .9)

            let exists = loadedImages.value.find( (item) => {
                return item.original == imgEl.value.src
            })

            if ( !exists ) {
                loadedImages.value.push({
                    original: imgEl.value.src,
                    updated: imgSrc
                })
            } else {
                exists.updated = imgSrc
            }

            activeImage.value = imgEl.value.src
        });
    }, 200)


    watch(
        () => imgEl.value,
        (newValue, oldValue) => {
            if ( imgEl.value ) {
                reflow()
            }
        },

    )
    watch(
        () => sharedData.value.backgroundImage,
        (newValue, oldValue) => {

            //if ( newValue && !loadedImages.value.includes(newValue) ) {
            //    loadedImages.value.push(newValue)
            //}

            //if ( oldValue ) {

                let img = new Image();
                img.crossOrigin = 'Anonymous';
                img.onload = () => {
                    //activeImage.value = newValue

                    //imgEl.value = document.querySelector('.img-hidden')
                    imgEl.value = img
                    //imgEl.value.crossOrigin="Anonymous"



                }

                img.src = newValue+'?decache'
            //} else {
            //    activeImage.value = newValue

            //}
        },

    )

</script>
