/*
|--------------------------------------------------------------------------
| Main entry point
|--------------------------------------------------------------------------
| Files in the "resources/scripts" directory are considered entrypoints
| by default.
|
| -> https://vitejs.dev
| -> https://github.com/innocenzi/laravel-vite
*/

import '@/css/app.css'

import { createApp, h, provide, ref, computed, watch } from 'vue'
import { createInertiaApp } from '@inertiajs/inertia-vue3';

import axios from 'axios'
import { Head, Link } from '@inertiajs/inertia-vue3'
const el = document.getElementById("app");

import '@iconify/iconify'
import '@purge-icons/generated'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'
import { useScroll } from '@vueuse/core'


import frontendLayout from './Frontend/Layouts/Index.vue'
const pages = import.meta.glob('./Frontend/Pages/**/*.vue');

import VueGoogleMaps from '@fawmi/vue-google-maps'

//PARTIALS
import PageHeader from './Frontend/Partials/PageHeader.vue'
import Masthead from './Frontend/Partials/Masthead.vue'
import MastheadImages from './Frontend/Layouts/Partials/MastheadImages.vue'

import RelatedContent from './Frontend/Partials/RelatedContent.vue'
import Modal from './Frontend/Partials/Modal.vue'

import ContactForm from './Frontend/Partials/Contact.vue'

import { InertiaProgress } from '@inertiajs/progress'
import Popper from "vue3-popper";
import { plugin as VueTippy } from 'vue-tippy'


import 'tippy.js/dist/tippy.css' // optional for styling
import 'tippy.js/animations/perspective.css'

import '@splidejs/splide/dist/css/splide.min.css';
import '@/css/splide.css';


import { textBalancer } from './Frontend/Lib/text-balancer.js'

import VueGtag from "vue-gtag-next";

import VueSplide from '@splidejs/vue-splide';

import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3'

InertiaProgress.init({
    // The delay after which the progress bar will
    // appear during navigation, in milliseconds.
    delay: 250,

    // The color of the progress bar.
    color: '#29d',

    // Whether to include the default NProgress styles.
    includeCSS: true,

    // Whether the NProgress spinner will be shown.
    showSpinner: false,
  })


const app = createInertiaApp({
    id: 'app',
    resolve: async (name) => {
        const importPage = pages[`./Frontend/Pages/${name}.vue`];
        if (!importPage) {
            throw new Error(`Unknown page ${name}. Is it located under Pages with a .vue extension?`);
        }
        return importPage().then((module) => {
            let page = module.default
            page.layout = page.layout || frontendLayout
            return page
        });
    },
    setup({ el, app: App, props, plugin }) {
      const wndr = createApp({
          render: () => h(App, props),
          setup() {

            const sharedDataDefaults = {
                backgroundImage: null,
                scrollDir: 'down',
                scrollPane: null,
                classes: {
                    scrollPane: 'py-8 px-8'
                }
            }
            const sharedDataObject = ref({...sharedDataDefaults})
            const sharedData = computed({
                get: () => {
                    return sharedDataObject.value
                },
                set: (value) => {
                    sharedDataObject.value = value
                }
            })
            provide('sharedDataDefaults', sharedDataDefaults)
            provide('sharedData', sharedData)



            provide('axios', axios)
          }
        })
        .component('Link', Link)
        .component('Head', Head)
        .component('VueFinalModal', VueFinalModal)
        //.component('ModalsContainer', ModalsContainer)
        .component('PageHeader', PageHeader)
        .component('Masthead', Masthead)
        .component('MastheadImages', MastheadImages)
        .component("Popper", Popper)
        .component('RelatedContent', RelatedContent)
        .component('Modal', Modal)
        .component('ContactForm', ContactForm)
        .use(
            VueTippy,
            // optional
            {
              directive: 'tippy', // => v-tippy
              component: 'tippy', // => <tippy/>
              componentSingleton: 'tippy-singleton', // => <tippy-singleton/>
              defaultProps: {
                  theme: 'wg',
                  placement: 'top',
                  animation: "perspective",
                  offset: [0, 4]
              }
            }
        )
        .use(plugin)
        .use(VueSplide)
        .use(VueReCaptcha, {
            siteKey: import.meta.env.VITE_RECAPTCHA_PUBLIC,
            loaderOptions: {
                autoHideBadge: true
            }
        })

        .directive('balance-text', {
            mounted (el, binding, vnode, oldVnode) {
                const balancedEl = new textBalancer( el )
                balancedEl.initialize()
            }
        })
        .use(VueGoogleMaps, {
            load: {
                key: import.meta.env.VITE_GOOGLE_API_KEY,
                libraries: "places"
            },
        });
        //wndr.provide('$page', computed(() => wndr.config.globalProperties.$page));

        if ( import.meta.env.PROD ) {
            wndr.use(VueGtag, {
                property: {
                  //id: "G-W01YFMXEET"
                  id: gtagId
                }
            })
        }
        wndr.mount('#app');
    },
})



