<template>
	<div class="wonderguide-wrapper w-screen bg-[#F3F3F2] antialiased flex flex-col lg:flex-row text-body text-sans" :class="{ 'content-loading': contentLoading }" ref="siteWrapperRef">

        <Head :title="metaTitle"></Head>
        <div class="wonderguide-masthead-wrapper border-b-4 lg:border-b-0 lg:border-r-[3px] border-[#EBECE7] flex items-stretch flex-grow min-w-0 min-h-[380px] md:min-h-[420px] lg:h-screen" :class="{ 'lg:p-6': !mobileOpen }">
            <div class="wonderguide-masthead flex lg:shadow-mast lg:block w-full min-h-full p-6 min-w-0 relative">

                <MastheadImages></MastheadImages>

                <div class="masthead-content text-white flex flex-col justify-between w-full flex-grow lg:h-full">

                    <div class="masthead-topper">
                        <div class="menu-wrapper flex justify-between items-center mx-1 mt-2 mb-4 flex-row">
                            <div class="title-wrapper flex gap-1.5 items-center">
                                <SiteIcon v-if="props.is_primary" class="w-8 h-8"></SiteIcon>
                                <h3 class="text-logo font-bold uppercase leading-[34px] whitespace-nowrap" v-html="account.name"></h3>
                            </div>

                            <div class="flex justify-end min-w-0 flex-grow" ref="menuWrapper">

                                <template v-if="showMobileMenu">
                                    <teleport :to="windowWidth < 768 ? 'body' : '.wonderguide-masthead'">
                                        <transition name="mobile-menu">
                                            <MastheadMobileMenu v-if="mobileOpen">
                                                <!--<a class="absolute cursor-pointer top-3 right-3 w-[44px] h-[44px] text-[28px] text-white flex items-center justify-center" @click="mobileOpen = !mobileOpen">
                                                    <span class="iconify" data-icon="ph:x-bold"></span>
                                                </a>-->
                                            </MastheadMobileMenu>
                                        </transition>

                                        <a class="mobile-menu-toggle group fixed md:absolute transition-menu-toggle duration-500 ease-in-out cursor-pointer text-[20px] h-[42px] w-[42px] z-[19] flex items-center justify-center rounded-full" @click="mobileOpen = !mobileOpen" :class="{'text-gray-900 top-4 right-4 bg-[#F3F3F2]': windowY > 360 && !mobileOpen, 'text-[#F3F3F2] top-7 right-7': windowY <= 360, 'text-[#F3F3F2] top-5 right-5': mobileOpen }">
                                            <!--<span class="iconify" data-icon="heroicons-solid:menu"></span>-->
                                            <div class="lines flex flex-col justify-center w-[16px] h-[38px] relative">
                                                <span class="line transition-all duration-300 bg-current w-full rounded-[3px] origin-center absolute" :class="{'translate-y-[-5px] h-[3px]': !mobileOpen, 'rotate-[-45deg] h-[3px]': mobileOpen}"></span>
                                                <span class="line transition-all duration-300 bg-current w-full h-[3px] rounded-[3px]" :class="{'opacity-0': mobileOpen}"></span>
                                                <span class="line transition-all duration-300 bg-current w-full rounded-[3px] origin-center absolute" :class="{'translate-y-[5px] h-[3px]': !mobileOpen, 'rotate-[45deg] h-[3px]': mobileOpen}"></span>
                                            </div>
                                            <svg class="circle-border stroke-[.15rem] absolute z-[-1] w-[36px] h-[36px] top-[3px] right-[3px] group-hover:animate-rotate"><circle cx="50%" cy="50%" r="46%"></circle></svg>


                                        </a>
                                    </teleport>
                                </template>

                                <MastheadMenu ref="desktopMenu" v-if="!showMobileMenu"></MastheadMenu>

                            </div>

                        </div>
                        <svg class="line-border flex-grow h-[2px] z-[-1] w-full">
                            <line x1="0" y1="50%" x2="100%" y2="50%"  />
                        </svg>
                        <div class="info px-2 py-3">
                            <span class="text-[.8rem] uppercase font-semibold tracking-wider" v-html="`${guide.name} EDITION`"></span>
                        </div>
                    </div>

                    <div class="masthead-title lg:p-2.5 w-full mt-[80px] lg:mt-0">
                        <h3 class="uppercase font-sans text-[.8rem] font-bold tracking-widest pl-[4px]" v-html="subtitle" v-if="subtitle"></h3>
                        <h2 class="text-[2.2rem] md:text-[2.6rem] lg:text-[2.7rem] max-w-[32rem] font-semibold leading-[1.1em]" >
                            <span class="balance-text block" v-balance-text v-html="props.title"></span>
                        </h2>
                        <!--<h3 class="text-[1.5rem] font-medium tracking-wide pl-[4px]" v-html="byline" v-if="byline"></h3>-->
                        <h3 class="text-[1.5rem] font-medium tracking-wide pl-[4px]" v-if="subheading">
                            <span class="font-semibold text-[1.4rem]" v-html="subheading"></span>
                        </h3>
                        <div class="action-slot"></div>

                        <div class="nav-filler mt-7" v-if="props.showBottomBar">
                            <svg class="line-border flex-grow mx-2 h-[2px] z-[-1] w-full">
                                <line x1="0" y1="50%" x2="100%" y2="50%"  />
                            </svg>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <main class="wonderguide-main w-[100%] lg:w-[50%] mx-auto min-w-0 h-screen flex-shrink-0 bg-white md:min-w-[600px]">
            <div ref="scrollPane" class="md:max-h-full min-h-full scroll-pane lg:overflow-y-auto">
                <slot />
            </div>
        </main>

    </div>
</template>

<script setup>
    import { DomHandler } from 'primevue/utils';
    import SiteIcon from '@/images/icon.svg';
    import { ref, onMounted, computed, inject, watch, nextTick, provide } from 'vue'
    import { usePage } from '@inertiajs/inertia-vue3';
    import { Inertia } from '@inertiajs/inertia';
    import { useWindowSize, useResizeObserver, useWindowScroll, useElementSize } from '@vueuse/core'
    import { disablePageScroll, enablePageScroll } from 'scroll-lock';

    import { useGtag } from "vue-gtag-next";

    import MastheadMenu from './Partials/MastheadMenu.vue'
    import MastheadMobileMenu from './Partials/MastheadMobileMenu.vue'

    import { textBalancer } from '../Lib/text-balancer.js'

    const siteWrapperRef = ref()

    //const menuRef = ref()
    const showMobileMenu = ref(false)
    const mobileOpen = ref(false)

    const { width:windowWidth, height: windowHeight } = useWindowSize()
    const { y: windowY } = useWindowScroll()

    const menuWrapper = ref()
    const desktopMenu = ref()
    //const { width: siteWrapperWidth, height: siteWrapperHeight } = useElementSize(siteWrapperRef)
    const { width: menuWidth, height: menuHeight } = useElementSize(desktopMenu)
    const { width: menuWrapperWidth, height: menuWrapperHeight } = useElementSize(menuWrapper)

    const initialMenuWidth = ref(0)

    watch(
        () => mobileOpen.value,
        (newVal, oldVal) => {
            if ( newVal ) {
                disablePageScroll()
            } else {
                enablePageScroll()
            }
        }
    )





    const checkResponsive = async () => {

        //showMobileMenu.value = siteWrapperWidth.value < 1100 || menuWrapperWidth.value < menuWidth.value
        if ( initialMenuWidth.value == 0 ) {

            let fontsReady = await document.fonts.ready;

            initialMenuWidth.value = menuWidth.value;
        }

        showMobileMenu.value = windowWidth.value < 768 || menuWrapperWidth.value < initialMenuWidth.value


    }

    useResizeObserver(siteWrapperRef, (entries) => {
        checkResponsive()
    })


    const props = usePage().props



    const sharedData = inject('sharedData')

    const setScrollPane = (el) => {
        sharedData.value.scrollPane = el
    }

    const scrollPane = ref()

    const account = computed(() => {
        return usePage().props.value.account
    })
    const guide = computed(() => {
        return usePage().props.value.guide
    })
    const subtitle = computed(() => {
        return props.value.subtitle || sharedData.value.subtitle
    })

    const byline = computed(() => {
        return props.value.byline || sharedData.value.byline
    })

    const author = computed(() => {
        return sharedData.value.author || false
    })

    const subheading = computed(() => {
        return sharedData.value.subheading || false
    })

    const metaTitle = computed(() => {
        if ( !props.value.title ) return 'Wonderguides.com'
        return props.value.title.replace(/(<([^>]+)>)/gi, "") + ' | Wonderguides.com'
    })

    const baseItems = {
        'stories': {
            url: '/stories',
            label: 'Stories'
        },
        'todos': {
            url: '/things-to-do',
            label: 'Things to Do'
        },
        'tours': {
            url: '/tours',
            label: 'Tours'
        },
        'spotlight': {
            url: '/spotlight',
            label: 'Spotlight'
        },
        'visit': {
            url: '/visit',
            label: 'Visit'
        }
    }
    const menuItems = computed(() => {
        let finalItems = []
        Object.entries(baseItems).forEach(([key, item]) => {
            if ( guide.value.counts[key] ) {
                finalItems.push( item )
            }
        })

        return finalItems
    })

    const contentLoading = ref(false)

    const { pageview } = useGtag()

    const plannerLoaded = ref(false)
    provide('plannerLoaded', plannerLoaded)

    Inertia.on('navigate', (event) => {


        console.log('navigating', event)

        let page_path = event.detail.page.url.split('#')[0]
        let page_title = props.value.title.replace(/(<([^>]+)>)/gi, "")
        let viewProps = { page_path: page_path, page_title: page_title };

        //if (import.meta.env.PROD) pageview(viewProps);
    })

    Inertia.on('start', (event) => {

        contentLoading.value = true
        mobileOpen.value = false
    })

    Inertia.on('finish', (event) => {
        contentLoading.value = false
        scrollPane.value.scrollTop = 0
//props.value.title.replace(/(<([^>]+)>)/gi, "")
        //pageview({ page_path: event.detail.page.url });

    })


    //const titleBalancer = new textBalancer({ alwaysRun: true })
    onMounted( () => {
    //    titleBalancer.initialize()



    })

    onMounted( () => {

        if ( !!account.value.filters.api_keys.planner  ) {

            if ( !document.querySelector('#everwondr-planner-el') ) {
                var plannerEl = document.createElement("everwondr-planner");
                plannerEl.id = 'everwondr-planner-el'
                plannerEl.setAttribute( 'hide-toggles', true )
                plannerEl.setAttribute( 'api-key', account.value.filters.api_keys.planner );

                document.body.appendChild(plannerEl);
            }

            if ( !document.querySelector('#everwondr-planner-script') ) {
                let plannerScript = document.createElement("script");
                plannerScript.async = true;
                plannerScript.id = "everwondr-planner-script"
                plannerScript.src = "https://tripriffic.com/e/ew-planner-"+account.value.filters.api_keys.planner+".js";
                document.body.appendChild(plannerScript);

                plannerScript.onload = () => {
                    setTimeout(
                        () => {
                            plannerLoaded.value = true
                        },
                        500
                    )

                }

            }

        }


    })



</script>
<style lang="postcss">

    .masthead-content, .mobile-menu-toggle {
        text-shadow: 1px 1px 0 rgba(0 0 0 / 20%);
        filter: drop-shadow(1px 1px 0px rgb(0 0 0 / 35%)) drop-shadow(1px 1px 10px rgb(0 0 0 / 30%));
    }
    .wonderguide-masthead-wrapper {
        transition: padding .3s ease;
    }

    @media screen and (min-width: 1024px) and (max-width: 1280px) {
        .masthead-title {
            h2 {
                font-size: calc(10vw / 3.25);
            }
        }
    }

</style>
