<template>
    <vue-final-modal @closed="hide" v-model="visible" v-bind="$attrs" classes="modal-container" content-class="modal-content">
        <span class="modal__title">
            <slot name="title"></slot>
        </span>
        <div class="modal__content">
            <slot></slot>
        </div>
        <div class="modal__action" v-if="$slots.actions">
            <slot name="actions"></slot>
        </div>
        <button class="modal__close" @click="hide">
            <span class="iconify" data-icon="ph:x-bold"></span>
        </button>
    </vue-final-modal>
</template>
<script setup>

    import { computed, ref } from 'vue'

    const props = defineProps({
        padding: {
            type: String,
            default: "1rem"
        },
        width: {
            type: String,
            default: '100%'
        },
        maxWidth: {
            type: String,
            default: '580px'
        },
        height: {
            type: String,
            default: 'auto'
        },
        maxHeight: {
            type: String,
            default: '90%'
        }
    })

    const emit = defineEmits([
        'closed',
        'opened'
    ])

    const visible = ref(false)
    const show = () => {
        visible.value = true
    }
    const hide = () => {
        visible.value = false
        emit('closed')
    }


    defineExpose({
        visible,
        show,
        hide
    })

</script>
<style lang="postcss" scoped>
    ::v-deep(.modal-container) {
    display: flex;
    justify-content: center;
    align-items: center;

    }
    ::v-deep(.modal-content) {
        position: relative;
        display: flex;
        flex-direction: column;
        width: v-bind(props.height);
        max-height: v-bind(props.maxHeight);
        margin: 0 1rem;
        padding: v-bind(props.padding);
        //border: 1px solid #e2e8f0;
        border-radius: 0.25rem;
        background: #fff;
        width: v-bind(props.width);
        max-width: v-bind(props.maxWidth);
    }
    .modal__title {
    margin: 0 2rem 0 0;
    font-size: 1.5rem;
    font-weight: 700;
    }
    .modal__content {
    flex-grow: 1;
    overflow-y: auto;
    }
    .modal__action {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    padding: 1rem 0 0;
    }
    .modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    }
</style>
