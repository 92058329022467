<template>
    <div :style="{ 'width': width }" class="related-content z-20 py-4 rounded-t transition-appearance duration-[.4s] ease-out w-[220px] text-body bg-beige bg-opacity-90 fixed bottom-0 right-5 " :class="{'translate-y-0 is-open border-1 border-beige-darkest related-shadow': open, 'translate-y-full': !open}">

        <!--<div class="absolute bottom-[155px] left-0 rotate-90 transform origin-right" >
        <a @click="toggle" class="related-toggle block rounded-b transition-colors duration-200 ease-linear whitespace-nowrap absolute px-3 py-1.5 cursor-pointer opacity-80 text-[.87rem] font-semibold" :class="{'opacity-100 bg-beige-darkest text-white border-t-1 border-b-1 border-l-1 border-beige-darkest bg-opacity-90': open, 'hover:bg-[#A1A19D] bg-beige-darkest text-white': !open}">RELATED<span class="hidden md:visible"> CONTENT</span></a>
        </div>-->

        <div class="absolute h-32px bottom-[100%] w-full px-2 flex justify-end" >
            <a @click="toggle"
            @mouseenter="hovering = true"
            @mouseleave="hovering = false"
            class="related-toggle
            rounded-t transition-appearance duration-200
            ease-linear whitespace-nowrap relative h-[32px]
            px-4
            flex
            items-center
            gap-1.5
            cursor-pointer opacity-80 hover:opacity-90 text-[.87rem] font-semibold"
            :class="{'opacity-100 bg-gray-700 text-white bg-opacity-90': open, 'hover:bg-gray-500 text-white bg-gray-700': !open}">

                <span class="whitespace-nowrap">
                    RELATED<span class="hidden md:inline"> CONTENT</span>
                </span>
                <!--<span class="iconify" data-icon="ph:arrow-square-up-bold"></span>-->
            </a>
        </div>

        <div class="related-sections grid gap-3 max-h-[80vh] min-h-[150px] px-4 overflow-y-auto">
            <slot />


            <div class="origin-right" v-for="(section, sdx) in related" :key="sdx">

                <div class="section-header flex items-center">
                    <h3 class="font-sans text-beige-darkest uppercase text-xs font-bold" v-html="section.title"></h3>
                    <span class="h-0 ml-2 flex-grow border-b border-dashed border-beige-darker"></span>
                </div>
                <div class="related-items py-3 grid gap-3">
                    <a class="related-item cursor-pointer flex items-center group" :class="{ 'flex-col': item.layout == 'vertical' }" v-for="(item, idx) in section.items" :key="idx" v-bind="handleAttrs(item)" @click="handleClick(item)">
                        <div class="related-image relative shadow overflow-hidden rounded flex-shrink-0 " :class="{ 'w-1/3 aspect-12/8': item.layout !== 'vertical', 'w-full h-[120px]': item.layout == 'vertical' }">
                            <img class="block w-full h-full object-cover" :src="item.image" :alt="item.title" />

                            <div class="related-image-overlay pointer-events-none flex text-2xl items-center justify-center absolute top-0 left-0 w-full h-full bg-beige-darker bg-opacity-50" v-if="item.type == 'pdf' || item.type == 'video'">
                                <span class="iconify" data-icon="ph:play-fill" v-if="item.type == 'video'"></span>
                                <span class="iconify" data-icon="ph:download-simple-fill" v-if="item.type == 'pdf'"></span>
                            </div>
                        </div>
                        <div class="related-content ml-2 flex-grow opacity-70 hover:opacity-100 transition-opacity" :class="{'m-2': item.layout == 'vertical'}">
                            <h4 class="text-menu-item font-semibold leading-tight font-sans" v-html="item.title"></h4>
                        </div>
                    </a>
                </div>


            </div>
        </div>

        <teleport to="body">
            <transition name="fade">
            <div v-if="open" class="related-overlay z-[1] fixed top-0 left-0 bg-body bg-opacity-40 w-screen h-screen backdrop-blur-[3px]" @click="toggle"></div>
            </transition>
            <Modal padding="0" max-width="500px" class="video-modal" ref="videoModal" @closed="activeVideo = false">

                <div class="activeVideo" v-if="activeVideo" v-html="activeVideo"></div>

            </Modal>
        </teleport>

    </div>
</template>
<script setup>
    import {ref} from 'vue'
    const props = defineProps({
        related: {
            default: []
        },
        width: {
            default: '240px'
        }
    })
    const videoModal = ref()
    const activeVideo = ref(false)

    const galleryModal = ref()

    const hovering = ref(false)


    const setHover = () => {
        if (!open.value) {
            hovering.value = true
        }
    }

    const generateEmbed = (id, type) => {

        if ( type == 'youtube' ) {
            return `<iframe width="100%" class="aspect-12/7"
                    src="https://www.youtube.com/embed/${id}"frameborder="0"
                    allowfullscreen></iframe>`
        } else if ( type == 'vimeo') {

            return `<iframe width="100%" class="aspect-12/7"
                    src="https://player.vimeo.com/video/${id}"frameborder="0"
                    allowfullscreen></iframe>`
        }
    }

    const open = ref(false)

    const handleAttrs = (item) => {

        let attrs = {}
        if ( item.type == 'event' ) {
            attrs.href = `#/event/${item.object.url_title}`
        } else if ( item.type == 'venue' ) {
            attrs.href = `#/venue/${item.object.url_title}`
        } else if ( item.type == 'post' ) {
            attrs.href = `#/post/${item.object.url_title}`
        } else if (item.type == 'custom' ) {
            attrs.href = item.url
            attrs.target = "_blank"
        } else if (item.type == 'pdf') {
            attrs.href = item.file
            attrs.target = "_blank"

        }

        return attrs
    }

    const handleClick = (item) => {
        let type = item.type


        if ( type == 'video' ) {
            activeVideo.value = generateEmbed(item.object.id, item.object.provider)

            videoModal.value.show()
        }

        return;
    }

    const toggle = () => {
        open.value = !open.value
    }

</script>
<style lang="postcss">
    .related-content {
        will-change: transform;
    }
    .related-shadow {
        filter: drop-shadow(rgba(17, 17, 26, 0.1) 0px 4px 16px) drop-shadow(rgba(17, 17, 26, 0.1) 0px 8px 24px) drop-shadow(rgba(17, 17, 26, 0.1) 0px 16px 56px);
    }



</style>

