<template>
    <div class="menu-wrapper desktop-menu flex flex-shrink-0 relative">

        <div class="menu-items flex justify-end flex-shrink-0">
            <template v-for="(item, key) in menuItems" :key="key">
                <Link class="text-[.87rem] leading-[26px] tracking-wider whitespace-nowrap transition duration-300 font-semibold uppercase menu-item"
                    :class="{'text-yellow-400 hover:text-yellow-300': $page.url.startsWith(urlPrefix+item.url), 'hover:opacity-70': !$page.url.startsWith(urlPrefix+item.url) }"
                    :href="urlPrefix+item.url"
                    v-html="item.label" />
                <span class="menu-bullet flex items-center h-[25px]" v-if="key < menuItems.length - 1"></span>
            </template>
        </div>

    </div>

</template>
<script setup>
    import { usePage } from "@inertiajs/inertia-vue3"
    import { computed, ref } from "vue"



    const urlPrefix = computed(() => usePage().props.value.urlPrefix)

    const guide = computed(() => {
        return usePage().props.value.guide
    })

    const baseItems = {
        'stories': {
            url: '/stories',
            label: 'Stories'
        },
        'todos': {
            url: '/things-to-do',
            label: 'Things to Do'
        },
        'events': {
            url: '/events',
            label: 'Calendar'
        },
        'tours': {
            url: '/tours',
            label: 'Tours'
        },
        'spotlight': {
            url: '/spotlight',
            label: 'Spotlight'
        },
        'visit': {
            url: '/visit',
            label: 'Visit'
        }
    }
    const menuItems = computed(() => {
        let finalItems = []
        Object.entries(baseItems).forEach(([key, item]) => {
            if ( key == 'events' ) {
                if ( usePage().props.value.account.filters.api_keys.calendar ) {
                    finalItems.push( item )
                }
            } else if ( guide.value.counts[key] ) {
                finalItems.push( item )
            }
        })
        return finalItems
    })

</script>
